import { Event, NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Logger } from './../utils/logger';
import { ScreenNameProvider } from './screen-name-provider';
import { UserIdProvider } from './user-id-provider';

/**
 * Tell the TS compiler the gtag function exists elsewhere
 */
declare function gtag(...params: any[]): any;

/**
 * The user may have an adblocker that prevents google analytics from working.
 * This wrapper function ensures our code is executed even when gtagjs fails to execute.
 *
 * @example
 * GoogleAnalytics.trackEvent('some_click', {event_callback: doIfGAFails(() => console.log('finished'))});
 */
export const doIfGAFails = (callback: () => any, timeoutMS?: number) => {
  let executed = false;
  const wrapper = () => {
    if (!executed) {
      executed = true;
      callback();
    }
  };
  setTimeout(callback, timeoutMS || 1000);
  return wrapper;
};

/**
 * Namespace that wraps the google analytics tracking logic so it's cleaner from outside
 */
export class GoogleAnalytics {
  private static _logger: Logger | null = null;
  /**
   * This property determines if the gtag script is installed in the webpage
   */
  private static _hasGoneThroughSetup = false;
  /**
   * Flag to check whether we want to associate events to a specific user.
   * To enable it requires the implementation of the UserIdProvider
   */
  private static _identifyUser = false;

  private static userIdProvider: UserIdProvider | null = null;

  /**
   * This is the standard key of an event indicating a screen appearing
   * as per the official documentation
   */
  private static readonly screenViewEventName = 'screen_view';

  /**
   * This flag is used to determine whether to send or to not send data
   * to Google Analytics
   */
  private static _isEnabled = false;

  private static GA_APPNAME: string;
  private static GA_PROJ_ID: string;
  private static GA_ANALYTICS_URL: string;
  private static routerSubscription: Subscription | null = null;
  private static screenNameProvider: ScreenNameProvider | null = null;

  /**
   * Provides the @param logger. Even if GA is not set-up and enabled we
   * are interested in seeing what we woulf be reporting if it was.
   */
  public static provideLogger(logger: Logger) {
    GoogleAnalytics._logger = logger;
  }

  /**
   * Provides the @param logger. Even if GA is not set-up and enabled we
   * are interested in seeing what we woulf be reporting if it was.
   */
  public static provideScreenNameProvider(screenNameProvider: ScreenNameProvider) {
    GoogleAnalytics.screenNameProvider = screenNameProvider;
  }

  /**
   * Sets up tracking.
   * Asyncly loads the gtag.js and sets it up and sets listeners for the URLs
   *
   * @param appName application name like – Bernie's Sausage website!
   * @param googleAnalyticsId is the google analytics project ID, something like G-ADN7YHKGFB
   * @param router is required to track the URLs
   * @param screenNameProvider is the module required to analyse urls and return a human readable screen name
   */
  public static setup(
    appName: string,
    googleAnalyticsId: string,
    router: Router,
    screenNameProvider: ScreenNameProvider | null,
    logger: Logger | null = null
  ) {
    GoogleAnalytics.screenNameProvider = screenNameProvider;
    GoogleAnalytics._logger = logger;
    if (!GoogleAnalytics.isSetup()) {
      GoogleAnalytics.GA_APPNAME = appName;
      GoogleAnalytics.GA_PROJ_ID = googleAnalyticsId;
      GoogleAnalytics.GA_ANALYTICS_URL = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
      GoogleAnalytics.log(`Configuring GA for ${appName} and project ${googleAnalyticsId}`);
      GoogleAnalytics.loadGoogleAnalytics();
      GoogleAnalytics.setupScreenTracking(router);
      GoogleAnalytics._hasGoneThroughSetup = true;
      GoogleAnalytics.trackStatus()
    }
  }

  /**
   * Remember to call this method to clean up the router subscription
   */
  public static tearDown() {
    const rs = GoogleAnalytics.routerSubscription;
    if (rs != null) {
      rs.unsubscribe();
    }
  }

  /**
   * Checks whether it's safe to run a gtag action
   */
  public static isInstalled(): boolean {
    return GoogleAnalytics.isSetup() && GoogleAnalytics.gtagDidLoad();
  }

  /**
   * Tests whether the developer set up the tool properly
   */
  private static isSetup(): boolean {
    // Checks developer has gone through the setup.
    return GoogleAnalytics._hasGoneThroughSetup;
  }

  /**
   * Tests whether the developer set up the tool properly
   */
  private static gtagDidLoad(): boolean {
    // If gtag is not a function, then there's something blocking gtag in the browser
    return typeof gtag === 'function';
  }

  /**
   * Enables sending data to Google Analytics
   */
  public static enable() {
    GoogleAnalytics._isEnabled = true;
  }

  /**
   * Disables sending data to Google Analytics
   */
  public static disable() {
    GoogleAnalytics._isEnabled = false;
  }

  /**
   * Method that checks whether the google analytics will actually send events
   */
  public static isEnabled(): boolean {
    return GoogleAnalytics._isEnabled;
  }

  public static enableUserIdentification(idProvider: UserIdProvider) {
    GoogleAnalytics.setUserIdentification(idProvider);
  }

  public static disableUserIdentification() {
    this.enableUserIdentification(null);
  }

  public static isUserIdentificationEnabled(): boolean {
    return this._identifyUser;
  }

  public static trackStatus() {
    const enabled = GoogleAnalytics.isEnabled();
    const symbol = enabled ? 'ENABLED' : 'DISABLED';
    GoogleAnalytics.log(`GA is ${symbol}`);
  }

  private static setUserIdentification(idProvider: UserIdProvider | null) {
    const userTrackingEnabled = idProvider != null;
    const uuid = idProvider?.getUserId() || null;
    const logSentence = userTrackingEnabled ? `enabled user tracking (${uuid}) ` : 'disabled user tracking';
    GoogleAnalytics._identifyUser = userTrackingEnabled;
    GoogleAnalytics.userIdProvider = idProvider;
    GoogleAnalytics.log(`🕵🏻‍♂️  ${logSentence}`);

    GoogleAnalytics.useGtag(() =>
      gtag('config', GoogleAnalytics.GA_APPNAME, {
        user_id: uuid,
      })
    );
  }

  /**
   * Checks whether this has been set up and runs @param action
   */
  private static doIfSetup<T>(action: () => T): T | null {
    const setup = GoogleAnalytics.isSetup();
    if (!setup) {
      GoogleAnalytics.warn(
        '🤔 Looks like you havent gone through the setup process.',
        'Have you forgot calling GoogleAnalytics.setup?'
      );
    }
    return setup ? action() : null;
  }

  /**
   * Performs sanity checks before running gtag and if those pass
   * it runs @param action
   */
  private static useGtag(action: () => void) {
    const enabled = GoogleAnalytics.isEnabled();

    if (!enabled) {
      GoogleAnalytics.warn(
        `🤔 GoogleAnalytics is DISABLED so nothing will happen. Any messages spit out are for debugging purposes`
      );
    }

    // If enabled reporting to the world, else to your laptop :)!
    const symbol = enabled ? 'ENABLED' : 'DISABLED';
    // Minify a little bit the lambda code
    const lambdaCode = action.toString().replaceAll(/\n|\(\)|\s=>\s|(\s\s)+/g, '');
    // GoogleAnalytics.log(`GA is ${symbol} we would've called:`, lambdaCode);

    if (!GoogleAnalytics.isSetup()) {
      GoogleAnalytics.warn(
        '🤔 GoogleAnalytics is not setup so nothing will happen. Any messages spit out are for debugging'
      );
      return;
    }
    // At this point developer set up the script properly
    if (enabled) {
      // Developer set the script up, AND he wants to report to GA.
      if (!GoogleAnalytics.gtagDidLoad()) {
        GoogleAnalytics.error('🚫 gtag is blocked');
      } else {
        // developer set it up, wants to report to GA AND gtag was successfully loaded into the browser
        action();
      }
    }
  }

  /**
   * Just sends to the arguments to console log.
   *
   * Useful if we desire to disable logging in production
   */
  private static log(...messages: any[]) {
    GoogleAnalytics._logger?.info(...messages);
  }

  private static warn(...messages: any[]) {
    GoogleAnalytics._logger?.warn(...messages);
  }

  private static error(...messages: any[]) {
    GoogleAnalytics._logger?.error(...messages);
  }

  /**
   * Function that loads google analytics
   */
  private static loadGoogleAnalytics() {
    GoogleAnalytics.log(`Setting up the script gtag from ${GoogleAnalytics.GA_ANALYTICS_URL}`);
    if (GoogleAnalytics.isEnabled()) {
      const body = document.body;
      const asyncLoader = GoogleAnalytics.createScript();
      const setupScript = GoogleAnalytics.createScript();

      GoogleAnalytics.configureAsyncLoader(asyncLoader);
      GoogleAnalytics.configureSetupScript(setupScript);
      body.appendChild(asyncLoader);
      body.appendChild(setupScript);
    }
  }

  /**
   * Creates a script element
   */
  private static createScript(): HTMLScriptElement {
    return document.createElement('script');
  }

  /**
   * Configures a script to asyncly load the gtagjs
   *
   * @param script
   */
  private static configureAsyncLoader(script: HTMLScriptElement) {
    script.setAttribute('async', 'true');
    script.setAttribute('src', GoogleAnalytics.GA_ANALYTICS_URL);
  }

  /**
   * Actual JS script that runs the basic setup for gtagjs
   *
   * @param script
   */
  private static configureSetupScript(script: HTMLScriptElement) {
    /**
     * Not using a single value because emmbeds undesired <br> elements
     */
    script.innerText += `/* Global site tag (gtag.js) - Google Analytics */`;
    script.innerText += `window.dataLayer = window.dataLayer || [];`;
    script.innerText += `function gtag(){dataLayer.push(arguments);}`;
    script.innerText += `gtag('js', new Date());`;
    script.innerText += `gtag('config', '${GoogleAnalytics.GA_PROJ_ID}');`;
    script.innerText += `console.info('Done loading GA!!')`;
  }

  /**
   * Sets up the application tracking
   */
  private static setupScreenTracking(router: Router) {
    const navigationStream = router.events.pipe(
      // Filter router events that indicate that navigation has finished
      filter((event) => event instanceof NavigationEnd)
    );

    GoogleAnalytics.routerSubscription = navigationStream.subscribe((nextParams) =>
      GoogleAnalytics.onNavigationEnd(nextParams)
    );
  }

  /**
   * Actual tracking callback
   *
   * @param navigationEvent
   */
  private static onNavigationEnd(navigationEvent: Event | NavigationEnd) {
    /* This is to make the compiler shut up, the filter pipe already handles this for us */
    if (!(navigationEvent instanceof NavigationEnd)) {
      return;
    }
    const url = navigationEvent.url;

    GoogleAnalytics.trackURLChanged(url, /* submitScreenName */ true);
  }

  /**
   * Tracks a URL change.
   * Accordingly, send the event 'screen_view' with the screen name if trackScreenName was set to true
   *
   * @param url
   * @param submitScreenName
   */
  public static trackURLChanged(url: string, submitScreenName: boolean = false) {
    const gtagOptions = GoogleAnalytics.doIfSetup(() => {
      // Basic URL tracking
      const screenNameProvider = GoogleAnalytics.screenNameProvider;
      const screenName = screenNameProvider.getScreenNameFromUrl(url);
      return { page_title: screenName, page_path: url };
    });
    GoogleAnalytics.log(`URL CHANGED ▶️ '${url}'🚀`);
    if (gtagOptions) {
      GoogleAnalytics.useGtag(() => gtag('config', GoogleAnalytics.GA_PROJ_ID, gtagOptions));
    }

    if (submitScreenName) {
      // gtagOptions?.page_title contains the screen name
      GoogleAnalytics.trackScreen(gtagOptions?.page_title);
    }
  }

  /**
   * Calls `gtag('event', @param screenName, @param gtagOptions);
   * performing all additional checks (isInstalled, isEnabled)
   */
  public static trackScreen(screenName: string, gtagOptions?: any) {
    const name = GoogleAnalytics.GA_APPNAME;
    // The app_name field is now set for the property, so
    // screen_view events don't need to include it.
    GoogleAnalytics.useGtag(() => gtag('config', GoogleAnalytics.GA_PROJ_ID, { app_name: name }));

    const screenNameOptions = { screen_name: screenName };
    const fullOptions = gtagOptions != null ? { ...gtagOptions, ...screenNameOptions } : screenNameOptions;
    const screenView = GoogleAnalytics.screenViewEventName;
    GoogleAnalytics.trackEvent(screenView, fullOptions);
  }

  /**
   * Wrapper to gtag('event', @param eventName, @param options);
   *
   * It just performs additional checks of "isInstalled" and "isEnabled"
   * Please refer to google analytics documentation for further info.
   * Add filter i developer console "GA TRACKEVENT" to get overview of trackevent calls
   */
  public static trackEvent(eventName: string, options?: any) {
    if (eventName === GoogleAnalytics.screenViewEventName) {
      const screenName = options?.screen_name || 'unknown screen';
      GoogleAnalytics.log('GA TRACKEVENT 🖥 SCREEN', `'${screenName}'`, options);
    } else {
      GoogleAnalytics.log('GA TRACKEVENT 👨🏻‍💻 EVENT', eventName, options);
    }

    GoogleAnalytics.useGtag(() => gtag('event', eventName, options));
  }

  public static trackLink(eventName: string, link: { text: string; href: string }, options?: any) {
    const safeOpts = options || {};
    GoogleAnalytics.trackEvent(eventName, { ...link, ...safeOpts });
  }

  /**
   * Iterates over @param eventNames doing what has been described in GoogleAnalytics.trackEvent
   */
  public static trackEvents(eventNames: string[], options?: any) {
    eventNames.forEach((event) => GoogleAnalytics.trackEvent(event, options));
  }
}

/**
 * Annotation for tracking single or multiple events with the gtag() function
 *
 * @example
 * \@TrackEvent('login-click')
 * public onLogin() {
 * }
 *
 * @param eventNames
 * @param gtagOptions
 */
export function TrackEvent(eventNames: string | string[], gtagOptions?: any) {
  /**
   * target: target object that has tagged with the annotation
   * propertyKey: name of the target, in this case method name
   * propertyDescriptor: Metadata of the object that has been annotated
   */
  return (target: any, propertyKey: string, propertyDescriptor: PropertyDescriptor) => {
    // Grab the original method
    const originalMethod = propertyDescriptor.value;
    if (typeof originalMethod === 'function') {
      // Replace the original method with our own wrapper function
      // eslint-disable-next-line space-before-function-paren
      propertyDescriptor.value = function () {
        if (typeof eventNames === 'string') {
          // It's a single string
          GoogleAnalytics.trackEvent(eventNames, gtagOptions);
        } else {
          // It's an array
          GoogleAnalytics.trackEvents(eventNames, gtagOptions);
        }
        // Execute the original method code
        return originalMethod.apply(this, arguments);
      };
    } else {
      console.error(`@TrackEvent annotation on something it wasn't a method`);
    }
  };
}
