import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { ProductionEquipmentAccessService } from 'src/app/evida/profile/services/production-equipment-access.service'
import { AuthService } from '../auth/auth.service'
import { Menu, MenuItemId, SubmenuItem } from '../models/menu.model'

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menu: Menu[] = [
    new Menu(MenuItemId.frontpage, 'menu.frontpage', false, false, 'evida_menu_frontpage', '/evida'),
    new Menu(MenuItemId.documents, 'menu.documents.title', false, false, 'evida_menu_documents', null, [
      { id: MenuItemId.invoice, title: 'menu.documents.submenu.bill', routerLink: '/evida/invoice', selected: false }
    ]),
    new Menu(MenuItemId.consumption, 'menu.consumption.title', false, false, 'evida_menu_consumption', null, [
      { id: MenuItemId.development, title: 'menu.consumption.submenu.overview', routerLink: '/evida/development-consumption', selected: false },
      { id: MenuItemId.productionreadings, title: 'menu.consumption.submenu.production', routerLink: '/evida/production-readings', selected: false, hidden: true }
    ]),
    new Menu(MenuItemId.profile, 'menu.profile', false, false, 'evida_menu_profile', '/evida/profile'),
    new Menu(MenuItemId.logout, 'menu.logout', false, false, 'evida_menu_logout', '/login/logout'),
  ]
  private isOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private menuItems: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(this.menu)
  public isOpen$: Observable<boolean> = this.isOpen.asObservable()
  public menuItems$: Observable<Menu[]> = this.menuItems.asObservable()

  public constructor(
    private authsService: AuthService,
    private productionEquipmentAccessService: ProductionEquipmentAccessService
  ) {
    // Only add flow menu item for private customers
    if (this.authsService.isPrivateCustomer) {
      this.menu.filter(menuItem => menuItem.id === MenuItemId.consumption).map(item => {
        const flowMenuItem: SubmenuItem = { id: MenuItemId.follow, title: 'menu.consumption.submenu.track', routerLink: '/evida/follow-consumption', selected: false }
        item?.submenu.push(flowMenuItem)
        this.menuItems.next(this.menu)
      })
    }

    if (this.authsService.isBusinessCustomer) {
      this.productionEquipmentAccessService.productionEquipment$.subscribe(show => {
        this.menu.filter(menuItem => menuItem.id === MenuItemId.consumption).map(item => item.submenu.filter(i => {
          if (i.id === MenuItemId.productionreadings) {
            i.hidden = !show
          }
        }))
      })
    }
  }

  public menuItemSelected(menuItem: Menu) {
    this.deselectMenuItems(menuItem.id)
    menuItem.selected = !menuItem.selected

    if (menuItem.submenu === undefined) {
      this.deselectSubmenuItems()
      menuItem.active = true
    }
  }

  public submenuItemSelected(menuItem: SubmenuItem) {
    this.deselectSubmenuItems()
    menuItem.selected = true
  }

  public selectSubmenuItmById(id: MenuItemId) {
    this.menu.filter(items => items.submenu !== undefined)
      .map(parentItem => parentItem.submenu
        .filter(childItem => childItem.id === id).map(i => {
          i.selected = true
          parentItem.active = true
          parentItem.selected = true
        }))
  }

  public async preselectedMenuItem(id: MenuItemId) {
    // Deselect all menues items
    this.menu.forEach(item => {
      item.selected = false
      item.deselectSubmenuItems()
    })
    const menuItem = this.menu.find(items => items.id === id)
    if (menuItem) {
      this.menuItemSelected(menuItem)
    } else {
      this.selectSubmenuItmById(id)
    }
  }

  // Used by burgermenu on mobile devices
  public handleMenu() {
    const isMenuOpen = this.isOpen.getValue()
    this.disableScrollForBurgerMenu(isMenuOpen)
    this.isOpen.next(!isMenuOpen)
  }

  public close() {
    if (this.isOpen.getValue()) {
      this.isOpen.next(false)
      this.disableScrollForBurgerMenu(true)
    }
  }

  public setBurgerMenuOverflowToScroll() {
    this.disableScrollForBurgerMenu(true)
  }

  private deselectMenuItems(id: MenuItemId) {
    this.menu.forEach(item => {
      item.deselectMenuItems(id)
    })
  }

  private deselectSubmenuItems() {
    this.menu.forEach(item => {
      item.deselectSubmenuItems()
    })
  }

  private disableScrollForBurgerMenu(disable: boolean) {
    if (disable) {
      document.body.classList.remove('burger-menu')
    } else {
      document.body.classList.add('burger-menu')
    }
  }
}

