/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BusinessProfileAssociatedUsers } from './businessProfileAssociatedUsers';


export interface BusinessProfileCustomerInstallationDetailsResponse { 
    paymentServiceActive: boolean;
    /**
     * URL enabling payment service.  This will be null in the follow cases: Payment service is active, or the customer company id was not registered with a url in backend
     */
    paymentServiceUrl?: string | null;
    /**
     * da: Målernummer
     */
    meterNumber?: string | null;
    gasSupplyStartDate?: string | null;
    /**
     * da: Aftagernummer
     */
    meteringPoint?: string | null;
    supplierName?: string | null;
    /**
     * da: Korrektornummer
     */
    correctorNumber?: string | null;
    associatedUsers: BusinessProfileAssociatedUsers;
    hasInvoiceViaEmail: boolean;
    invoiceViaEmailEmail: string;
    eanNumber: string;
}

