import { BreakpointObserver } from '@angular/cdk/layout'
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { combineLatest, firstValueFrom } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { environment } from 'src/environments/environment'
import { RegisterIcons } from '../register-icons'
import { AnalyticsService } from '../shared/services/backend'
import { LoadingStateService } from '../shared/services/loading-state.service'
import { EvidaScreenNameProvider } from '../tracking/evida-screens'
import { EvidaUserIdProvider } from '../tracking/evida-user-id-provider'
import { GoogleAnalytics } from '../tracking/google-analytics'
import { Breakpoints } from '../utils/breakpoints'
import { Logger } from '../utils/logger'
import { BASE_PATH } from './../shared/services/backend/variables'

declare const Cookiebot: {
  consent: {
    marketing: boolean;
    statistics: boolean;
    preferences: boolean;
    necessary: boolean;
  };
}

interface AlternativeBrowser {
  iconFile: string;
  downloadLink: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit, OnDestroy {
  public breakpoints = [
    { class: 'xs', breakpoint: Breakpoints.xs },
    { class: 'sm', breakpoint: Breakpoints.sm_only },
    { class: 'md', breakpoint: Breakpoints.md_only },
    { class: 'lg', breakpoint: Breakpoints.lg_only },
    { class: 'xl', breakpoint: Breakpoints.xl },
  ]

  public alternativeBrowsers: AlternativeBrowser[] = [
    { iconFile: 'evida_firefox', downloadLink: 'https://www.mozilla.org/en-US/firefox/new/' },
    { iconFile: 'evida_chrome', downloadLink: 'https://www.google.com/intl/en_us/chrome/' },
    { iconFile: 'evida_opera', downloadLink: 'https://www.opera.com/' },
  ]

  public currentBreakpoint = ''

  public isLoading$!: Observable<boolean>

  constructor(
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    private logger: Logger,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private screenNameProvider: EvidaScreenNameProvider,
    @Inject(BASE_PATH) private basePath: string,
    private analytics: AnalyticsService,
    loadingService: LoadingStateService
  ) {
    this.isLoading$ = loadingService.isLoading$
    RegisterIcons.registerIcons(matIconRegistry, domSanitizer)
  }

  /**
   * Triggered when ANY of the subtypes of cookies besides the required ones are accepted.
   * As per our current Cookiebot declaration (11/03/2021), google analytics lives under `Cookiebot.consent.statistics`
   */
  @HostListener('window:CookiebotOnAccept', ['$event'])
  private async cookiebotOnAccept(_event) {
    this.logger.debug('window:CookiebotOnAccept, Cookiebot.consent.statistics:', Cookiebot.consent.statistics)
    this.setupGoogleAnalytics()
  }

  @HostListener('window:CookiebotOnDecline')
  private disableGA() {
    this.logger.debug('window:CookiebotOnDecline')
    GoogleAnalytics.disable()
    
    if (environment.ga.enabled) {
      GoogleAnalytics.provideLogger(this.logger)
      GoogleAnalytics.trackStatus()
    }
  }

  private async setupGoogleAnalytics() {  
    let statisticsAccepted = Cookiebot.consent.statistics
    let trackingEnabled = environment.ga.enabled

    if (statisticsAccepted && trackingEnabled) {
      // Enable google analytics.
      GoogleAnalytics.enable()
      const userIdProvider = EvidaUserIdProvider.get()
      await this.setAnalyticsUserId(userIdProvider.getUserId())
      GoogleAnalytics.setup(
        environment.ga.appName,
        environment.ga.GA_PROJ_ID,
        this.router,
        this.screenNameProvider,
        this.logger
      )
      GoogleAnalytics.enableUserIdentification(userIdProvider)
    }
    else {
      this.disableGA()
    }
  }

  public async ngOnInit() {
    this.setupGoogleAnalytics()
    GoogleAnalytics.provideLogger(this.logger)
    GoogleAnalytics.provideScreenNameProvider(this.screenNameProvider)

    this.currentBreakpoint = 
      this.breakpoints.find((item) => this.breakpointObserver.isMatched(item.breakpoint))?.class || ''

    combineLatest([
      this.breakpointObserver.observe(this.breakpoints[0].breakpoint),
      this.breakpointObserver.observe(this.breakpoints[1].breakpoint),
      this.breakpointObserver.observe(this.breakpoints[2].breakpoint),
      this.breakpointObserver.observe(this.breakpoints[3].breakpoint),
      this.breakpointObserver.observe(this.breakpoints[4].breakpoint),
    ]).subscribe((combination) => {
      const breakpointIndex = combination.findIndex((item) => item.matches)
      if (breakpointIndex >= 0) {
        this.currentBreakpoint = this.breakpoints[breakpointIndex].class
      }
    })
  }

  public ngOnDestroy(): void {
    GoogleAnalytics.tearDown()
  }

  private setAnalyticsUserId(userId: string): Promise<void> {
    const body = JSON.stringify(userId)
    return (
      firstValueFrom(this.analytics.apiAnalyticsPost(body))
        .then(()=>{})
        .catch(()=>{})
    )
  }
}
